*,
*:before,
*:after {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

*::placeholder {
  color: #808080;
}

body {
  margin: 0;
  font-family: "Poppins", -apple-system, "BlinkMacSystemFont", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "source-code-pro", "Menlo", "Monaco", "Consolas", "Courier New",
    monospace;
}

.editGoalsButton {
  left: 24px;
  bottom: 12px;
  border: none;
  color: #fff;
  padding: 12px;
  font-size: 18px;
  position: fixed;
  font-weight: 600;
  line-height: 1.5;
  font-style: normal;
  border-radius: 33px;
  font-family: Poppins;
  font-stretch: normal;
  background: transparent;
  width: calc(100% - 48px);
  border: solid 1.5px #fff;
  box-shadow: 0 5px 5px 0 rgba(119, 51, 255, 0.05);
}

.editGoalsButton:hover {
  background: rgba(255, 255, 255, 0.1);
}
